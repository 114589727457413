export default [
  {
    nameFirst: 'Jacob C.',
    nameLast: 'Kimmel',
    email: 'jacob@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },
  {
    nameFirst: 'David R.',
    nameLast: 'Kelley',
    email: 'drk@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },
];
